<template>
  <v-card>
    <v-list style="padding-top:0;">
      <v-tabs v-model="tab" background-color="transparent" color="basil" grow>
        <v-tab v-for="item in itemsList" :key="item">
          {{ item }}
        </v-tab>
      </v-tabs>
      <v-tabs-items v-model="tab">
        <v-tab-item>
          <div>
            <v-card>
              <v-card-title>
                <v-spacer></v-spacer>
                <v-text-field class="searchStyle" v-model="search" append-icon="mdi-magnify" label="جست و جو" single-line hide-details></v-text-field>
              </v-card-title>
              <v-data-table :headers="headers" :items="deposits.filter((item) => item.transferToMainWallet === false)" :search="search" style="font-size: 5px !important;">
                <template v-slot:item.createdAtUtc="{item}">
                  {{getPersianDate(item.createdAtUtc)}}
                </template>
                <template v-slot:item.receiverAddress="{item}">
                  <v-tooltip bottom>
                    <template v-slot:activator="{ on }">
        <span
            v-on="on"
        >{{str(item.receiverAddress)}}</span>
                    </template>
                    <span>{{item.receiverAddress}}</span>
                  </v-tooltip>
                </template>
                <template v-slot:item.tatumAccountId="{item}">
                  <v-tooltip bottom>
                    <template v-slot:activator="{ on }">
        <span
            v-on="on"
        >{{str(item.tatumAccountId)}}</span>
                    </template>
                    <span>{{item.tatumAccountId}}</span>
                  </v-tooltip>
                </template>
                <template v-slot:item.firstName="{item}">
                  {{item.firstName + ' ' + item.lastName}}
                </template>
                <template v-slot:item.operations="{ item }">
                  <v-btn @click="showDialog(item)" style="margin-left: 10px" color="success" :disabled="!item.supportManualTransferToMainWallet">واریز به حساب مادر </v-btn>
                  <v-btn color="success">واریز به آدرس درخواستی</v-btn>
                </template>
              </v-data-table>
            </v-card>
          </div>
        </v-tab-item>
        <v-tab-item>
          <v-card-title>
            <v-spacer></v-spacer>
            <v-text-field class="searchStyle" v-model="search" append-icon="mdi-magnify" label="جست و جو" single-line hide-details></v-text-field>
          </v-card-title>
          <v-data-table :headers="headersDeposit" :items="deposits.filter((item)=> item.transferToMainWallet === true)" :search="search">
          </v-data-table>
        </v-tab-item>
      </v-tabs-items>
    </v-list>
    <Popup width="600px" v-if="dialogDeposit" :show.sync="dialogDeposit">
      <template slot="body">
        <v-toolbar color="primary" dark class="rtl">
          تایید درخواست
        </v-toolbar>
        <v-card-text class="rtl">
          <div>
            <div class="modal-content form-group" style="width: auto">
              <div style="padding: 4%">
                <p style="font-size: 15px">ادمین محترم ساترکس  </p>
                <p style="font-size: 15px">شما قصد انتقال رمز ارز با مشخصات ذیل را دارید : </p>
              </div>
              <div class="row">
              </div>
              <div style="display: flex;justify-content: space-between;">
                <div style="display: flex;">
                  <div>شناسه درخواست :  </div>
                  <div>{{this.item.id }}</div>
                </div>
                <div style="display: flex">
                  <div>شناسه کاربر : </div>
                  <div>{{ this.item.username }}</div>
                </div>
              </div>
              <div style="display: flex;justify-content: space-between;">
                <div style="display: flex">
                  <div>نام و نام خانوادگی کاربر :</div>
                  <div>{{ this.item.firstName  + ' ' + this.item.lastName}}</div>
                </div>
              </div>
              <div style="display: flex;justify-content: space-between;">
                <div style="display: flex">
                  <div>رمز ارز : </div>
                  <div>{{ this.item.assetSymbol }}</div>
                </div>
                <div style="display: flex">
                  <div>شبکه : </div>
                  <div>{{ this.item.networkSymbol }}</div>
                </div>
              </div>
              <div style="display: flex;justify-content: space-between;">
                <div style="display: flex">
                  <div>مقدار : </div>
                  <div>{{ this.item.amount }}</div>
                </div>
                <div style="display: flex">
                  <div>کارمزد انتقال : </div>
                  <div>{{ this.item.networkFee }}</div>
                </div>
              </div>
              <div>
                <div >
                  <div>اکانت تیتوم کاربر :</div>
                  <div style="text-align: center">
                    <span class="borderSpan">
                      {{ this.item.tatumAccountId }}
                    </span>
                  </div>
                </div>
              </div>
              <div>
                <div>
                  <div>آدرس مقصد : </div>
                  <div style="text-align: center">
                    <span class="borderSpan">
                      {{ this.item.receiverAddress }}
                    </span>
                  </div>
                </div>
              </div>
              <div>
                <div>
                  <div> xpub : </div>
                </div>
                <div style="text-align: center">
                  <span class="borderSpan" v-if="xPub">
                    {{ xPub }}
                  </span>
                </div>
              </div>
              <div>
                <div>
                  <div> mnemonic :</div>
                </div>
                <div  style="text-align: center">
                  <span class="borderSpan" v-if="mnemonic">
                    {{ mnemonic }}
                  </span>
                </div>
              </div>
              <div class="row" style="margin-top:20px">
                <v-text-field v-model="mnemonic" outlined label=" mnemonic :"></v-text-field>
              </div>
              <div class="row" style="margin-top:20px">
                <v-text-field v-model="xPub" outlined label="  xPub :"></v-text-field>
              </div>
              <div class="row" style="margin-top:20px">
                <v-text-field v-model="paymentTracingNumber" outlined label=" شماره پیگیری :"></v-text-field>
              </div>
              <div class="row" style="margin-top:20px">
                <v-text-field v-model="secret" outlined label="secret"></v-text-field>
              </div>
              <div class="row" style="margin-top:20px">
                <v-text-field v-model="comment" outlined label="comment"></v-text-field>
              </div>

              <div style="margin-right: 40%;margin-top: 10px">
                <v-btn color="success" @click="MoveExternalPaymentAmountToMainWallet(item)">تایید درخواست</v-btn>
              </div>
              <hr style="border-top-color: white">
              <div v-if="err">
                <div style="display: flex">
                  <div>completed : </div>
                  <div>{{this.errors.completed}}</div>
                </div>
                <div style="display: flex">
                  <div>httpStatusCode : </div>
                  <div>{{this.errors.httpStatusCode}}</div>
                </div>
                <div style="display: flex">
                  <div>id : </div>
                  <div>{{this.errors.id}}</div>
                </div>
                <div style="display: flex">
                  <div>txId : </div>
                  <div>{{this.errors.txId}}</div>
                </div>
              </div>
            </div>
          </div>
        </v-card-text>
      </template>
    </Popup>
  </v-card>
</template>

<script>
import axiosApi from "@/axios";
import Vue from "vue";
import Popup from "@/components/Popup";
import getPersianDateTime from "@/plugins/getPersianDate";
export default {
  components: { Popup },
  name: "Deposit",
  data() {
    return {
      itemsList: [ 'برداشت نشده', 'برداشت شده' ],
      search: '',
      item : '',
      paymentTracingNumber : '',
      xPub : '',
      secret : '',
      comment : '',
      err : false,
      errors : [],
      mnemonic : '',
      dialogDeposit : false,
      deposits: [],
      tab: null,
      headers: [
        { text: 'نام و فامیل', value: 'firstName' },
        { text: 'شناسه کاربری', value: 'username' },
        { text: ' رمز ارز ', value: 'assetSymbol' },
        { text: 'شبکه', value: 'networkSymbol' },
        { text: 'مقدار', value: 'amount' },
        { text: 'واریز شده به آدرس', value: 'receiverAddress' },
        { text: 'تاریخ ایجاد', value: 'createdAtUtc' },
        { text: 'derivationKey', value: 'derivationKey' },
        { text: 'tatumAccountId', value: 'tatumAccountId' },
        { text: 'عملیات', value: 'operations' },
      ],
      headersDeposit: [
        { text: 'نام و فامیل', value: 'firstName' },
        { text: 'شناسه کاربری', value: 'username' },
        { text: ' رمز ارز ', value: 'assetSymbol' },
        { text: 'شبکه', value: 'networkSymbol' },
        { text: 'مقدار', value: 'amount' },
        { text: 'واریز شده به آدرس', value: 'receiverAddress' },
        { text: 'تاریخ ایجاد', value: 'createdAtUtc' },
        { text: 'derivationKey', value: 'derivationKey' },
        { text: 'tatumAccountId', value: 'tatumAccountId' },
      ],
      fastBuy: '',
      fastSell: '',

    };
  }, mounted() {
    this.deposit()
  },
  methods: {
    getPersianDate(date) {
      return getPersianDateTime(date);
    },
    deposit() {
      axiosApi().post( 'api/Manage/v1/ExternalPayment/GetList',{})
        .then( ( { data } ) => {
          if ( data[ 'isSuccess' ] === true ) {
            this.deposits = data.data.items;
          }
        } );
    },
    showDialog(item){
      this.item = item
      this.dialogDeposit = true
    },
    str(item) {
      if (item === null)
        return ''
      return item.substring(0,5) + '...'
    },
    MoveExternalPaymentAmountToMainWallet(){
      axiosApi().post('api/Manage/v1/ExternalPayment/MoveExternalPaymentAmountToMainWallet',{
        "externalPaymentId": this.item.id,
        "mnemonic": this.mnemonic,
        "xPub": this.xPub,
        "secret": this.secret,
        "comment": this.comment,
        "paymentTracingNumber": this.paymentTracingNumber
      })
      .then(({data})=>{
        this.errors = data.data
        this.err = true
        if (data.data.completed === false)
        {
          Vue.$toast.open({
            message : 'مقادیر ارسالی نامعتبر است.',
            type : 'error'
          })
        }else {
          this.deposit()
          Vue.$toast.open({
            message : 'عملیات با موفقیت انجام شد.',
            type : 'success'
          })
        }
      })
    }
  }
};
</script>

<style scoped>
.borderSpan{
  border: 3px solid red;
  border-radius: 25px;
  padding: 0px 10px 0px 10px;
}
</style>
